import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FacebookShareButton } from 'react-share';
import { Image } from '../Common/Image';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from '../../richTextOptions';

const getData = graphql`
     query MaxYearNominees {
          allContentfulHamboneNominees(sort: { fields: nomineesYear, order: ASC }) {
               max(field: nomineesYear)
          }
     }
`;

const NomineesPetVoteOpen = ({ nominee }) => {
     const today = new Date();

     const dataQuery = useStaticQuery(getData);
     const getNewYear = dataQuery.allContentfulHamboneNominees?.max || today.getFullYear();

     const replacePetName = useCallback((string) => {
          return string.replace(/##nominee##/gi, petName.toUpperCase());
     }, []);
     const DAYS_TO_EXPIRE = 1;
     const COOKIE_NAME = 'nominee_voting_visitor';
     const [isVoted, setIsVoted] = useState(!!Cookies.get(COOKIE_NAME));

     const [currentHref, setCurrentHref] = useState('');
     useEffect(() => {
          if (window) {
               setCurrentHref(window.location.href);
          }
     }, []);

     const petName = nominee.nomineesPetName;
     const category = nominee.nomineesOfTheYear;

     const yearOfNominee = nominee.nomineesOfTheYear?.nomineesYear;

     let isVotingOn = (nominee.turnOnVotingForThisStory || (category.turnOnVotingForThisCategory && nominee.turnOnVotingForThisStory !== false)) && yearOfNominee === getNewYear; // story option overrides category option

     if (isVotingOn) {
          let votingButtonSet = true;
          let enabledText = '';
          let disabledText = '';
          let salesforceUrl = '';

          if (nominee.votingButton) {
               enabledText = replacePetName(nominee.votingButton.enabledText);
               disabledText = replacePetName(nominee.votingButton.disabledText);
               salesforceUrl = nominee.votingButton.salesforceUrl;
          } else if (category.votingButton) {
               enabledText = replacePetName(category.votingButton.enabledText);
               disabledText = replacePetName(category.votingButton.disabledText);
               salesforceUrl = category.votingButton.salesforceUrl;
          } else {
               console.log('Please set voting button for the category which this story belongs to or for this story');
               votingButtonSet = false;
          }

          const handleClose = () => {
               setShow(false);
          };

          const [show, setShow] = useState(false);
          const handleShow = () => setShow(true);

          const dataVotingButton = JSON.parse(JSON.stringify(nominee.votingButton).replace(/##petName##/g, nominee.nomineesPetName));

          const PopupVoting = () => {
               return (
                    <>
                         <Modal className="Popupsuccess popup-wacky-voting popup-hamboneaward-voting" show={show} onHide={handleClose}>
                              <div className="group-content-popup">
                                   <div className="content-popup">
                                        <Modal.Body className="text">
                                             {/* <strong>Thanks for voting for</strong>
                                             <h2>{nominee.nomineesPetName}</h2>
                                             <p>You can vote for {nominee.nomineesPetName} once per day from September 13-22, 2023.</p>
                                              {/* <p>Share who you voted for and enter to win!</p> */}
                                        </Modal.Body>
                                        <Modal.Footer>
                                             <Button variant="primary">
                                                  <FacebookShareButton url={currentHref} appId="623395759254133">
                                                       Share this pet
                                                  </FacebookShareButton>
                                             </Button>
                                        </Modal.Footer>
                                   </div>
                                   <div className="spacing-popup"></div>
                              </div>
                              <div className="image-popup">
                                   <Image image={nominee.nomineesPetFeaturedImage} />
                              </div>
                         </Modal>
                    </>
               );
          };

          var VotingButton = () => {
               if (!votingButtonSet) {
                    return <></>;
               }

               const voting = (e) => {
                    const votingPick = e.target.name;
                    let ipAddr = '';

                    axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((response) => {
                         if (response.status === 200) {
                              const myRegexp = /^(?:ip)=(.*)$/gm;
                              let responseString = response.data;
                              let match;

                              match = myRegexp.exec(responseString);
                              while (match != null) {
                                   ipAddr = ipAddr.concat(match[1] + '\n');
                                   match = myRegexp.exec(responseString);
                              }
                         } else {
                              ipAddr = guid();
                         }

                         let cookieCreateDate = dayjs();
                         let cookieExpireDate = cookieCreateDate.add(DAYS_TO_EXPIRE, 'day');
                         let cookieExpireTemp = cookieExpireDate.toISOString().slice(0, 10);

                         let cookieValue = {
                              ipAddr,
                              cookieExpireDate: cookieExpireDate.toString(),
                              votingChoice: petName
                         };

                         //set cookie
                         Cookies.set(COOKIE_NAME, JSON.stringify(cookieValue), { expires: DAYS_TO_EXPIRE });
                         setIsVoted(true);
                         handleShow();

                         if (salesforceUrl) {
                              const questionMarkIndex = salesforceUrl.indexOf('?');
                              let paramString = questionMarkIndex === -1 ? '' : salesforceUrl.substr(questionMarkIndex + 1);
                              // var url = `${salesforceUrl}${paramString ? '&' : '?'}ipAddress=${ipAddr}cookieExpireDate${encodeURIComponent(
                              //      cookieExpireTemp
                              // )}&votingPick=${encodeURIComponent(votingPick)}`;

                              var url = `${salesforceUrl}${paramString ? '&' : '?'}ipAddress=${ipAddr}&cookieExpireDate=${encodeURIComponent(
                                   cookieExpireTemp
                              )}&votingPick=${encodeURIComponent(votingPick)}`;

                              axios.post(url).then((response) => {
                                   // console.log(response);
                              });
                              // window.location.href = url;
                         }
                    });
               };

               return (
                    <>
                         {isVoted ? (
                              <>
                                   <PopupVoting />
                                   <button className="voting-btn disabled" disabled>
                                        {disabledText}
                                   </button>
                              </>
                         ) : (
                              <>
                                   <PopupVoting />
                                   <button className="voting-btn" name={petName} onClick={voting}>
                                        {enabledText}
                                   </button>
                              </>
                         )}
                    </>
               );
          };
     }

     if (!isVotingOn) {
          return <></>;
     }

     return <VotingButton />;
};

export default NomineesPetVoteOpen;